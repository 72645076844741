import Analytics from 'analytics'
import segmentPlugin from '@analytics/segment'

/* initialize analytics and load plugins */
export const analytics = Analytics({
  app: 'Boarding Flow',
  plugins: [
    segmentPlugin({
      writeKey: '0LKWcK4VbRprt5trAB4E8sDCBB1BQALf',
    })
  ]
})

window.Analytics = analytics
