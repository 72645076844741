import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../../../assets/logo-medium.png";

export const NavBarBrand = () => {
  return (
    <div className="nav-bar__brand">
      <NavLink to="/">
        <img
          className="nav-bar__logo"
          src={logo}
          alt="Boarding glor logo"
          width="36"
          height="50"
        />
      </NavLink>
    </div>
  );
};
