import React from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/material";

export const FreeTrialButton = () => {
const StyledButton = styled(Button)(({ theme, color = 'primary' }) => ({
  backgroundColor: "#e15498",
  color: "#fcfcfc",
  fontSize: "1.75rem",
  padding: "1rem 2rem",
  ':hover': {
    color: "#fcfcfc",
    backgroundColor: '#e15498',
    opacity: 0.8
  },
}));
  return <StyledButton>Get Free Trial</StyledButton>
}