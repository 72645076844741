import React from "react";
import { NavBarBrand } from "./nav-bar-brand";
import { NavBarButtons } from "./nav-bar-buttons";
import { NavBarTabs } from "./nav-bar-tabs";
// import { useAuth0 } from "@auth0/auth0-react";
// import { analytics } from "../../../analytics";

export const NavBar = () => {
  // const { user } = useAuth0();

  // analytics.identify(user && user.sub ? user.sub : undefined, {
  //   name: user && user.name ? user.name : undefined,
  //   email: user && user.email ? user.email : undefined
  // });

  return (
    <div className="nav-bar__container">
      <nav className="nav-bar">
        <NavBarBrand />
        <NavBarTabs />
        <NavBarButtons />
      </nav>
    </div>
  );
};
