import React from "react";
import { Container, Typography } from "@mui/material";
import { FreeTrialButton } from "./buttons/FreeTrialButton";
import { css } from "@emotion/react";

export const CallToAction = () => {
  return(
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "1.5rem",
        py: "3rem",
        "& *": { textAlign: "center" }
      }}
    >
      <Typography
        variant="h1"
        color="font.lightest"
        sx={{
          maxWidth: "700px"
        }}
      >
        Unlock Higher Conversions with Our Managed User Flow Solutions
      </Typography>
      <Typography
        variant="h2"
        color="font.light"
        sx={{ fontWeight: 400 }}
      >
        Convert more visitors and increase feature adoption
      </Typography>
      <FreeTrialButton />
    </Container>
  );
}