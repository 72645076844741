import React from "react";
import { NavLink } from "react-router-dom";
import { Typography } from "@mui/material";

export const NavBarTab = ({ path, label }) => {
  return (
    <NavLink
      to={path}
      end
      className={({ isActive }) =>
        "nav-bar__tab " + (isActive ? "nav-bar__tab--active" : "")
      }
    >
      <Typography 
        fontSize="1.5rem"
        sx={{
          ":hover": { opacity: 0.75 }
        }}
      >
        {label}
      </Typography>
    </NavLink>
  );
};
