import * as React from 'react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

export const CenteredTabs = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box 
      sx={{ 
        width: '100%', 
        bgcolor: 'blue.darkest',
      }}>
      <Tabs 
        value={value} 
        onChange={handleChange} 
        centered
        indicatorColor="secondary"
        textColor='white'
        sx={{
          "& *": {
            fontSize: "1.25rem"
          }
        }}
      >
        <Tab 
          label="Managed Flows" 
          sx={{ 
            color: "white",
          }} 
          onClick={e => console.log(e.target.value)}
        />
        <Tab 
          label="Event Tracking" 
          sx={{ 
            color: "white",
          }} 
        />
        <Tab 
          label="User Analytics" 
          sx={{ 
            color: "white",
          }} 
        />
        {/* <Tab label="Item Two" sx={{ color: "white" }} />
        <Tab label="Item Three" /> */}
      </Tabs>
    </Box>
  );
}
