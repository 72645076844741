import React from "react";
import { Auth0Features } from "../components/auth0-features";
import { HeroBanner } from "../components/hero-banner";
import { PageLayout } from "../components-mui/PageLayout";
import { analytics } from "../analytics";
import { CallToAction } from "../components-mui/CallToAction";
import { CenteredTabs } from "../components-mui/CenteredTabs";

export const HomePage = () => {
  // analytics.page();
  return (
  <PageLayout>
    <CallToAction />
    <CenteredTabs />
    {/* <HeroBanner /> */}
    {/* <Auth0Features /> */}
  </PageLayout>
);
}
