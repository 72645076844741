import React from "react";
import { Typography } from "@mui/material"

import { PageLayout } from "../components-mui/PageLayout";
export const DashboardPage = () => {
  return(
    <PageLayout maxWidth="full">
      <Typography>Welcome to the Dashboard</Typography>
    </PageLayout>
  )
}