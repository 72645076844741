import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";

export const SignInButton = () => {
  const { loginWithRedirect } = useAuth0();

  const handleSignIn = async () => {
    await loginWithRedirect({
      prompt: "login",
      appState: {
        returnTo: "/dashboard"
      }
    });
  }

  return(
    <Button 
      onClick={handleSignIn}
      variant="outlined" 
      sx={{ 
        color: "font.lightest", 
        borderColor: "font.lightest",
        mr: 2,
        fontSize: "1.25rem",
        ":hover": { color: "primary.main" }
      }}>
        Sign In
      </Button>
  );
}