import React from "react";
import { NavBar } from "./navigation/desktop/nav-bar";
import { MobileNavBar } from "./navigation/mobile/mobile-nav-bar";
import { PageFooter } from "./page-footer";
import { AppBar } from "../components-mui/navigation/AppBar";
import { Container } from "@mui/material";

export const PageLayout = ({ children }) => {
  return (
    <Container className="page-layout">
      <AppBar />
      {/* <NavBar />
      <MobileNavBar /> */}
      <div className="page-layout__content">{children}</div>
    </Container>
  );
};
