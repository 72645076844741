import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { App } from "./app";
import { Auth0ProviderWithNavigate } from "./auth0-provider-with-navigate";
import { ThemeProvider, Typography, createTheme } from "@mui/material";
import "./styles/styles.css";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const container = document.getElementById("root");
const root = createRoot(container);

const theme = createTheme({
  palette: {
    primary: {
      main2: "#F5672A",
      main: "#500AF5"
    },
    secondary: {
      main: "#e15498",
      offMain: "#c74c89",
      orange: "#fc845e",
      green: "#7dbe21"
    },
    blue: {
      darkest: "#111322",
      dark: "#141627",

      light: "#191c32",
      lighter: "#3d98cb",
      lightest: "#21253e"
    },
    font: {
      lightest: "#fcfcfc",
      light: "#767ba0"
    }
  }, 
  typography: {
    h1: {
      fontSize: "4.5rem",
      fontWeight: 600
    },
    h2: {
      fontSize: "1.75rem",
      fontWeight: 600
    },
    h3: {
      fontSize: "1.5rem",
      fontWeight: 600
    }
  }
});

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithNavigate>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
  </React.StrictMode>
);