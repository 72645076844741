import React from "react";
import { Container } from "@mui/material";
import { AppBar } from "./navigation/AppBar";
import { SideBar } from "./navigation/SideBar";

export const PageLayout = ({ children }) => {
  return(
    <>
      <Container sx={{ bgcolor: "blue.darkest", marginLeft: "8rem", bgcolor: "red" }} maxWidth="xl">
        <AppBar />zz
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
          }}
        >
          <SideBar />
          {children}
        </Container>
      </Container>
    </>
  );
};